import React from "react";

export default function PrivacyPolicy() {
  return (
    <body className='bg-gray-100 text-gray-800 font-sans'>
      <div className='max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md'>
        <h1 className='text-2xl font-bold mb-4'>Privacy Policy</h1>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>1. Introduction</h2>
          <p>
            This Privacy Policy describes how we collect, use, and protect your
            personal information when you use our website and services. This
            includes the use of tools such as Google Analytics and Facebook
            Pixel, as well as payment processing through Stripe. By accessing
            our website, you accept the practices described in this policy.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>
            2. Collected Information
          </h2>
          <p>We may collect the following information:</p>
          <ul className='list-disc list-inside'>
            <li>First and last name.</li>
            <li>Email address.</li>
            <li>Shipping and billing address.</li>
            <li>Payment data securely processed by Stripe.</li>
            <li>
              Information about your activity on our website collected through
              Google Analytics and Facebook Pixel, including:
              <ul className='list-disc ml-6'>
                <li>Pages visited.</li>
                <li>Time spent on the site.</li>
                <li>Interactions with our content.</li>
                <li>IP address and browser data.</li>
              </ul>
            </li>
          </ul>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>3. Use of Information</h2>
          <p>The collected information is used for the following purposes:</p>
          <ul className='list-disc list-inside'>
            <li>Process and complete your purchase.</li>
            <li>Send confirmations and updates related to your order.</li>
            <li>Respond to inquiries and provide customer support.</li>
            <li>
              Analyze user behavior on our website to improve the browsing
              experience and optimize our marketing strategies.
            </li>
          </ul>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>4. Use of Stripe</h2>
          <p>
            We use Stripe as our payment service provider. When making a
            purchase, your payment information is processed directly by Stripe
            and not stored on our servers. For more information on how Stripe
            handles your information, please refer to their{" "}
            <a
              href='https://stripe.com/privacy'
              className='text-blue-500 underline'
            >
              Privacy Policy
            </a>
            .
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>
            5. Use of Google Analytics and Facebook Pixel
          </h2>
          <p>
            <strong>Google Analytics</strong>: We use this tool to collect data
            about the use of our website, such as pages visited and time spent.
            This allows us to better understand user behavior and improve our
            website.
          </p>
          <p>
            <strong>Facebook Pixel</strong>: This tool allows us to track
            interactions on the website to offer personalized ads and measure
            the effectiveness of our advertising campaigns on Facebook.
          </p>
          <p>
            Both tools collect information anonymously and do not allow direct
            identification of users. You can opt out of data collection through
            your browser settings or by following the instructions on the
            settings pages of Google and Facebook.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>6. Data Protection</h2>
          <p>
            We maintain reasonable security measures to protect your personal
            information against unauthorized access, loss, or alteration. We do
            not share your personal information with third parties, except when
            necessary to process your order, optimize our platform, or comply
            with the law.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>
            7. Cookies and Related Technologies
          </h2>
          <p>
            Our website uses cookies and similar technologies to improve the
            user experience, collect statistics, and display personalized ads.
            You can manage your cookie preferences through your browser
            settings.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>8. User Rights</h2>
          <p>
            You have the right to access, update, or delete your personal
            information at any time. You can also opt out of analytical or
            advertising data tracking. To exercise these rights, please contact
            our customer service through the email provided on our website.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>
            9. Changes to this Policy
          </h2>
          <p>
            We reserve the right to modify this Privacy Policy at any time.
            Changes will be posted on this page with an update date. We
            recommend periodically reviewing this page to stay informed.
          </p>
        </section>

        <section className='mb-6'>
          <h2 className='text-xl font-semibold mb-2'>10. Contact</h2>
          <p>
            If you have questions or concerns about this Privacy Policy, please
            contact us through the email provided on our website.
          </p>
        </section>

        <p className='text-center text-gray-600 text-sm'>
          By using this website, you accept the terms set forth in this Privacy
          Policy.
        </p>
      </div>
    </body>
  );
}
