import React, { useEffect, useState } from "react";
import sections from "../data/sections";
import SectionHero from "../components/Landing/SectionHero";
import Section from "../components/Landing/Section";
import SectionBig from "../components/Landing/SectionBig";
import BuyHero from "../components/Landing/BuyHero";
import Buy from "../components/Landing/Buy";
import SectionComparison from "../components/Landing/SectionComparison";
import SectionPosibilities from "../components/Landing/SectionPosibilities";
import SectionPackage from "../components/Landing/SectionPackage";
import SectionFaq from "../components/Landing/SectionFaq";
import Menu from "../components/Landing/Menu";

import ContactForm from "../components/Landing/ContactFormEs";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";

export default function Landing() {
  const [showFloatingButton, setShowFloatingButton] = useState(false);
  const [showTopButton, setShowTopButton] = useState(false);
  const [isRevealed, setIsRevealed] = useState(false);

  const decodeEmail = () => atob("aW5mb0Bnb2xkZW5vcmlub2NvLmNvbQ=="); // Encoded email
  const decodePhone = () => atob("KzM0NjYwMjIxMTgw"); // Encoded phone

  const toggleReveal = () => {
    setIsRevealed(!isRevealed);
  };

  useEffect(() => {
    const handleScroll = () => {
      const buyNowButton = document.getElementById("buy-now-button");
      const buyNowRect = buyNowButton.getBoundingClientRect();
      const comprarDiv = document.getElementById("comprar");
      const comprarRect = comprarDiv.getBoundingClientRect();
      setShowFloatingButton(
        buyNowRect.top < 0 && comprarRect.top > window.innerHeight
      );
      setShowTopButton(buyNowRect.top < 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const rKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

  return (
    <div className='landing'>
      <div className='fixed md:hidden top-2 right-2 z-50'>
        <Menu />
      </div>
      <SectionHero />
      {showFloatingButton && (
        <div className='fixed bottom-3 left-2 sm:bottom-14 sm:left-14 z-50'>
          <a
            href='#comprar'
            className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            Comprar Ahora
          </a>
        </div>
      )}
      {showTopButton && (
        <div className='fixed bottom-3 right-2 sm:bottom-14 sm:right-14 z-50'>
          <a
            href='#top'
            className='rounded-full bg-slate-300 px-2.5 py-2 text-sm font-semibold text-gray-600 shadow-xs hover:bg-slate-100 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600'
          >
            ↑
          </a>
        </div>
      )}
      <div
        id='caracteristicas'
        className='mx-auto mt-12 max-w-7xl px-1 sm:px-6 lg:px-8'
      >
        {sections.map((section, index) =>
          section.position === "big" ? (
            <SectionBig key={index} section={section} />
          ) : (
            <Section key={index} section={section} />
          )
        )}
      </div>
      {/* <div id='social' className='mx-auto max-w-7xl px-1 sm:px-6 lg:px-8'>
        <Social />
      </div> */}
      <div
        id='package'
        className='flex flex-col items-center justify-center rounded-lg shadow-lg overflow-hidden mx-auto max-w-3xl mb-10 px-1 sm:px-6 lg:px-8'
      >
        <SectionPackage />
      </div>
      <div id='comparacion' className=''>
        <SectionComparison />
      </div>
      <div id='posibilidades'>
        <SectionPosibilities />
      </div>
      <div id='faq'>
        <SectionFaq />
      </div>
      <div id='comprar' className='p-1 sm:p-6 mt-10'>
        <BuyHero />
        <Buy />
      </div>
      <div id='contact' className='mx-auto max-w-3xl p-1 sm:p-6 mt-10'>
        <h1 className='text-2xl font-bold mb-4 text-center'>
          ¿Necesitas más información?
        </h1>
        <div className='flex items-center justify-evenly mb-4'>
          {isRevealed ? (
            <>
              <a
                href={`https://wa.me/${decodePhone().replace("+", "")}`}
                target='_blank'
                rel='noopener noreferrer'
                className='underline flex items-center space-x-2'
              >
                <FaWhatsapp />
                <span>{decodePhone()}</span>
              </a>
              <a
                href={`mailto:${decodeEmail()}`}
                className='underline flex items-center space-x-2'
              >
                <MdOutlineEmail />
                <span>{decodeEmail()}</span>
              </a>
            </>
          ) : (
            <button onClick={toggleReveal} className='text-sm underline'>
              Pincha aquí para contactarnos por WhatsApp or Email
            </button>
          )}
        </div>
        <GoogleReCaptchaProvider reCaptchaKey={rKey} language='es'>
          <ContactForm />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
}
