import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { RxHamburgerMenu } from "react-icons/rx";

export default function MobileMenu() {
  const links = [
    { href: "#top", label: "Top" },
    { href: "#caracteristicas", label: "Caracteristicas" },
    { href: "#package", label: "¿Qué incluye?" },
    { href: "#comparacion", label: "Eléctrica vs Combustión" },
    { href: "#posibilidades", label: "Posibilidades" },
    { href: "#faq", label: "Preguntas Frecuentes" },
    { href: "#comprar", label: "¡Comprar!" },
    { href: "#contact", label: "¿Dudas?" },
  ];

  return (
    <Menu>
      <MenuButton className='inline-flex items-center gap-2 rounded-md bg-slate-300 py-1.5 px-2 text-sm/6 font-semibold text-gray-600 shadow-inner shadow-white/10 focus:outline-none data-[hover]:bg-salte-700 data-[open]:bg-slate-100 data-[focus]:outline-1 data-[focus]:outline-white'>
        <RxHamburgerMenu size={24} />
      </MenuButton>
      <MenuItems
        transition
        anchor='bottom end'
        className='w-52 origin-top-right ml-2 mt-1 rounded-md bg-gray-500 p-2 text-sm/6 text-white transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0'
      >
        {links.map((link) => (
          <MenuItem
            key={link.href}
            className='block p-2 rounded-sm data-[focus]:bg-gray-600'
          >
            <a href={link.href}>{link.label}</a>
          </MenuItem>
        ))}
      </MenuItems>
    </Menu>
  );
}
