const sections = [
  {
    title: "Energía siempre lista para ti",
    description:
      "Estás de camping y tu nevera portátil deja de funcionar. Saliste a una sesión de fotos y la batería de tu cámara te obliga a detenerte. O quizás estás en la casa del pueblo y un apagón inesperado te deja sin luz ni electrodomésticos. Con el P2001E Plus, esas situaciones no pondrán a prueba tus nervios. Esta planta te da energía confiable, silenciosa y lista para mantener en marcha todo lo que necesitas, desde pequeños dispositivos hasta electrodomésticos esenciales.",
    image: "/images/P2001E/P2001E_landscape_500.webp",
    alt: "Estación de energía portátil suministrando energía a múltiples dispositivos",
    position: "right",
  },
  {
    title: "Energía equilibrada para tus necesidades",
    description:
      "Algunas baterías ofrecen más capacidad, pero su peso limita su portabilidad. Otras, más pequeñas, no están a la altura de problemas más grandes. El P2001E Plus combina lo mejor de ambos mundos: es lo suficientemente robusta para resolver inconvenientes importantes y lo suficientemente ligera para seguir siendo portátil. Además, al combinarla con paneles solares, se transforma en una solución aún más sostenible y versátil.",
    image: "/images/P2001E/P2001E_AC_charging_500.webp",
    alt: "Generador portátil a baterías siendo cargado",
    position: "left",
  },
  {
    title: "Protección instantánea contra cortes de energía",
    description:
      "Tu tranquilidad está asegurada con el modo UPS del P2001E Plus. En menos de 10 ms, cambia automáticamente para mantener tus equipos funcionando. Protege tu computadora, refrigerador y cualquier dispositivo esencial sin perder un solo segundo.",
    image: "/images/P2001E/P2001E_Plus_ups_mode.webp",
    alt: "Estación de energía portátil en modo UPS",
    position: "right",
  },
  {
    title: "Ligero para su potencia, práctico en cualquier lugar",
    description:
      "El P2001E Plus pesa 22 kilos, un equilibrio ideal para transportarlo sin esfuerzo y aprovechar su potencia donde lo necesites. Su diseño compacto y portátil lo hace perfecto para aventuras, proyectos en exteriores o emergencias en casa. Con energía confiable siempre a tu alcance, este generador combina movilidad y rendimiento como ningún otro.",
    image: "/images/P2001E/P2001E_Plus_dust_moisture_resistant.webp",
    alt: "Estación de energía portátil resistente al polvo y humedad",
    position: "left",
  },
  {
    title: "Energía limpia y silenciosa",
    description:
      "El P2001E Plus representa la evolución natural hacia una energía limpia y sostenible. Sin humos, sin emisiones, y aún más ecológico cuando lo combinas con paneles solares. Con un nivel de ruido de menos de 50 dB, es tan silencioso como una conversación tranquila en casa. Eso significa que puedes usarlo en el campo, en tu jardín o incluso durante la noche sin molestar a nadie. Es compacto, eficiente y está diseñado para ser el compañero perfecto en emergencias o aventuras.",
    image: "/images/P2001E/P2001E_Plus_low_noise.webp",
    alt: "Estación de energía portátil silencioso",
    position: "below",
  },
  {
    title: "Durabilidad que te acompaña por años",
    description:
      "Invierte en un generador diseñado para durar. Con baterías de grado automotriz LiFePO4, el P2001E Plus ofrece hasta 3,500 ciclos de recarga, garantizando más de 10 años de vida útil, incluso con uso intensivo. Además, su estructura protectora totalmente mejorada lo hace resistente al polvo y la humedad, asegurando un rendimiento confiable en cualquier entorno.",
    image: "/images/P2001E/P2001E_batteries.webp",
    alt: "Baterías de la estación portátil",
    position: "below",
  },
  {
    title: "Controla tu energía desde cualquier lugar",
    description:
      "Tú tienes el control. Con la app inteligente, monitorea y gestiona el P2001E Plus desde tu smartphone. Ajusta configuraciones, verifica niveles y optimiza tu energía, incluso cuando estás fuera de casa.",
    image: "/images/P2001E/P2001E_app_control.webp",
    alt: "Control inteligente con app",
    position: "below",
  },
  {
    title: "Energía accesible para tus aventuras y el teletrabajo",
    description:
      "Energía accesible para tus aventuras y el teletrabajo El P2001E Plus te da la independencia energética que necesitas, ya sea para camping, teletrabajo en la naturaleza o tu casa del pueblo. Si tienes una furgoneta camperizada, es la solución perfecta: evita la homologación o complementa la batería existente para viajes largos, alimentando neveras, luces, laptops y más.",
    image: "/images/P2001E/P2001E_solar_background.webp",
    alt: "Estacion electrica a bateria en el campo",
    position: "big",
  },
];

export default sections;
