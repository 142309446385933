import { Outlet } from "react-router-dom";
import Footer from "../Landing/LandingFooter";
import { Helmet } from "react-helmet";
import { LANDING_PAGE_URL } from "../../constants/routes";

export default function LandingRoot() {
  return (
    <>
      <Helmet>
        <title>
          Oukitel P2001 Plus | Estación de Energía Portátil para Emergencias y
          Aventuras
        </title>
        <meta
          name='description'
          content='Descubre la Estación de Energía Portátil Oukitel P2001 Plus. Ideal para emergencias, aventuras al aire libre y más. Energía confiable siempre contigo.'
        />
        <meta
          property='og:title'
          content='Oukitel P2001 Plus | Estación de Energía Portátil para Emergencias y Aventuras'
        />
        <meta
          property='og:description'
          content='Descubre la Estación de Energía Portátil Oukitel P2001 Plus. Ideal para emergencias, aventuras al aire libre y más. Energía confiable siempre contigo.'
        />
        <meta
          property='og:image'
          content='https://goldenorinoco.com/images/P2001E/P2001E_Plus_4_front-small.webp'
        />
        <meta property='og:url' content={`${LANDING_PAGE_URL}`} />
        <meta property='og:type' content='website' />
        <meta property='og:locale' content='es_ES' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:title'
          content='Oukitel P2001 Plus | Estación de Energía Portátil para Emergencias y Aventuras'
        />
        <meta
          name='twitter:description'
          content='Descubre la Estación de Energía Portátil Oukitel P2001 Plus. Ideal para emergencias, aventuras al aire libre y más. Energía confiable siempre contigo.'
        />
        <meta
          name='twitter:image'
          content='https://goldenorinoco.com/images/P2001E/UP2001E_camping-1230-615.webp'
        />
        <script type='application/ld+json'>
          {`
          {
            "@context": "https://schema.org",
            "@type": "Product",
            "name": "Oukitel P2001 Plus",
            "description": "Descubre la Estación de Energía Portátil Oukitel P2001 Plus. Ideal para emergencias, aventuras al aire libre y más. Energía confiable siempre contigo.",
            "image": "https://goldenorinoco.com/images/P2001E/P2001E_Plus_4_front-small.webp",
            "brand": "Oukitel",
            "offers": {
              "@type": "Offer",
              "priceCurrency": "EUR",
              "price": "899",
              "availability": "https://schema.org/InStock"
            }
          }
          `}
        </script>
      </Helmet>
      <Outlet />
      <Footer />
    </>
  );
}
