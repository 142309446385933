import React from "react";

export default function Section({ section }) {
  return (
    <div className='mx-auto max-w-3xl mb-4'>
      <div
        className={`flex flex-col ${
          section.position === "left"
            ? "md:flex-row-reverse"
            : section.position === "below"
            ? "flex-col"
            : "md:flex-row"
        }`}
      >
        <div
          className={`flex-1 px-4 sm:px-0 ${
            section.position === "left" && "md:ml-4"
          }`}
        >
          <h3 className='text-2xl font-bold'>{section.title}</h3>
          <p className={`mt-1`}>{section.description}</p>
        </div>
        <div
          className={`flex-1 ${
            section.position === "below"
              ? "mt-4"
              : "md:ml-4 mt-4 md:mt-0 shrink-0"
          }`}
        >
          <img
            alt={section.alt}
            src={section.image}
            className='w-full sm:rounded-lg'
          />
        </div>
      </div>
      {/* <hr className='my-8 border-t-2 border-gray-300' /> */}
    </div>
  );
}
