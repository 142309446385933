import ContactForm from "../components/ContactForm";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

const ContactUs = () => {
  const rKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;

  return (
    <div className='sm:px-8 mt-16 sm:mt-32'>
      <section className='relative px-4 sm:px-8 lg:px-12'>
        <div className='mx-auto max-w-2xl lg:max-w-5xl'>
          <div className='grid grid-cols-1 gap-y-16 lg:grid-cols-2 lg:grid-rows-[auto_1fr] lg:gap-y-12'>
            <div className='lg:pr-20 group'>
              <div className='max-w-xs px-2.5 lg:max-w-none'>
                <img
                  src='/images/contact-us.webp'
                  alt='Classic typewriter machine'
                  loading='lazy'
                  width='800'
                  height='800'
                  className='aspect-square -rotate-3 rounded-2xl bg-zinc-100 object-cover group-hover:rotate-0 group-hover:scale-[1.04]'
                />
              </div>
              <div className='absolute -rotate-3 mt-1 ml-10 p-1 bg-slate-200/80 text-slate-400 group-hover:rotate-0 group-hover:ml-5 group-hover:mt-2 dark:bg-black'>
                <p className='text-xs'>
                  Photo by{" "}
                  <a href='https://unsplash.com/@markuswinkler?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash'>
                    Markus Winkler
                  </a>{" "}
                  on{" "}
                  <a href='https://unsplash.com/photos/text-q3QPw37J6Xs?utm_content=creditCopyText&utm_medium=referral&utm_source=unsplash'>
                    Unsplash
                  </a>
                </p>
              </div>
            </div>
            <div className='lg:row-span-2'>
              <h1 className='text-2xl font-bold mb-4'>Contact Us</h1>
              <h2 className='text-lg font-semibold mb-4'>Let's talk</h2>
              <GoogleReCaptchaProvider reCaptchaKey={rKey} language='en'>
                <ContactForm />
              </GoogleReCaptchaProvider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ContactUs;
