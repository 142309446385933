import React from "react";

export default function SectionComparison() {
  return (
    <div className='py-12 bg-gray-100 my-10'>
      <div className='mx-auto max-w-3xl px-6 lg:px-8'>
        <h2 className='text-3xl font-semibold text-center text-gray-800 mb-8'>
          Plantas de Combustión vs P2001E Plus
        </h2>
        <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
          ¿Por qué las plantas de combustión se están quedando atrás?
        </h3>
        <p className='text-gray-700 mb-6'>
          Los generadores de combustión fueron útiles en su tiempo, pero
          enfrentan varias desventajas que hoy resultan difíciles de justificar:
        </p>
        <ul className='list-disc list-inside text-gray-700 space-y-4 mb-8'>
          <li>
            <span className='font-semibold'>
              Dependencia constante del combustible:
            </span>{" "}
            Tener que comprar gasolina o diésel no solo es un gasto extra, sino
            también un problema de logística, especialmente en lugares remotos.
          </li>
          <li>
            <span className='font-semibold'>Emisiones contaminantes:</span> Los
            generadores de combustión emiten gases tóxicos que afectan la
            calidad del aire y la salud de las personas.
          </li>
          <li>
            <span className='font-semibold'>Ruido molesto:</span> Con niveles de
            ruido altos, suelen interrumpir la tranquilidad, ya sea en casa o en
            un entorno natural.
          </li>
          <li>
            <span className='font-semibold'>
              Contaminación ambiental y olores:
            </span>{" "}
            Emisiones de CO₂ y un olor persistente que hacen incómodo su uso
            prolongado.
          </li>
          <li>
            <span className='font-semibold'>Mantenimiento engorroso:</span>{" "}
            Cambios de aceite, limpieza y revisiones constantes aumentan su
            complejidad.
          </li>
        </ul>
        <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
          Lo que el P2001E Plus ofrece en su lugar:
        </h3>
        <ul className='list-disc list-inside text-gray-700 space-y-4 mb-8'>
          <li>
            <span className='font-semibold'>Silencio absoluto:</span> Con menos
            de 50 dB, apenas notarás que está funcionando.
          </li>
          <li>
            <span className='font-semibold'>Energía limpia y sostenible:</span>{" "}
            Sin emisiones ni humos, y aún más ecológica cuando se combina con
            paneles solares.
          </li>
          <li>
            <span className='font-semibold'>
              Portabilidad sin complicaciones:
            </span>{" "}
            Con un diseño compacto y fácil de transportar, es ideal para
            aventuras, proyectos y emergencias.
          </li>
          <li>
            <span className='font-semibold'>Sin costos adicionales:</span>{" "}
            Olvídate del combustible y el mantenimiento intensivo; carga el
            generador y listo.
          </li>
        </ul>
        <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
          El futuro de la energía portátil
        </h3>
        <p className='text-gray-700 mb-6'>
          El P2001E Plus no solo resuelve los problemas de las plantas de
          combustión, sino que lo hace de manera eficiente, limpia y moderna. Es
          el paso lógico hacia una energía más responsable y práctica para tu
          día a día.
        </p>
        <h3 className='text-2xl font-semibold text-gray-800 mb-4'>
          ¿Estás listo para dar el salto hacia la energía portátil del futuro?
        </h3>
      </div>
    </div>
  );
}
