import React from "react";
import { Link } from "react-router-dom";
import { LANDING_PAGE_URL } from "../../constants/routes";

const Footer = () => {
  function getCurrentYear() {
    const currentDate = new Date();
    return currentDate.getFullYear();
  }

  return (
    <footer className='flex-shrink-0'>
      <nav className='flex justify-center space-x-4 text-xs text-slate-500 hover:text-slate-700 underline'>
        <Link to={`${LANDING_PAGE_URL}/warranty`}>Política de Garantía</Link>
        <Link to={`${LANDING_PAGE_URL}/terms-conditions`}>
          Términos & Condiciones
        </Link>
        <Link to={`${LANDING_PAGE_URL}/politica-privacidad`}>
          Política de Privacidad
        </Link>
      </nav>
      <p className='mt-6 text-sm text-slate-500 sm:mt-0'>
        Copyright © {getCurrentYear()} · Golden Orinoco, LLC · Wilmington, DE,
        USA
      </p>
    </footer>
  );
};

export default Footer;
