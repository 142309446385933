import React from "react";

export default function SectionBuy() {
  return (
    <>
      <div className='mx-auto max-w-4xl text-center'>
        <h2 className='text-base/7 font-semibold text-indigo-600'>Comprar</h2>
        <p className='mt-2 text-5xl font-semibold tracking-tight text-balance text-gray-900 sm:text-6xl'>
          Tu generador, a tu medida
        </p>
      </div>
      <p className='mx-auto mt-6 max-w-2xl text-center text-lg font-medium text-pretty text-gray-600 sm:text-xl/8'>
        Elige el pack que mejor se adapte a ti: energía rápida o aprovechando el
        poder del sol. Con ambos, tienes un práctico alargador para empezar a
        usar tu generador desde el primer momento. La energía portátil
        simplificada como nunca antes.
      </p>
    </>
  );
}
