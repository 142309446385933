import { FaCheck } from "react-icons/fa6";
import { FaFilePdf } from "react-icons/fa";

export default function SectionPackage() {
  return (
    <>
      <img
        src='/images/P2001E/p2001e-lista-empaque.jpg'
        alt='Que trae en la caja de la P2001E Plus'
        className='w-full'
      />
      <div className='bg-white bg-opacity-75 p-8 max-w-lg'>
        <h2 className='text-3xl font-semibold text-gray-800 mb-4'>
          Lista de empaque:
        </h2>
        <ul className='list-inside text-left text-gray-700'>
          <li className='flex items-center'>
            <FaCheck className='h-5 w-5 text-green-500 mr-2' />
            Estación de Energía Oukitel P2001 Plus
          </li>
          <li className='flex items-center'>
            <FaCheck className='h-5 w-5 text-green-500 mr-2' />
            Cable de Carga Solar
          </li>
          <li className='flex items-center'>
            <FaCheck className='h-5 w-5 text-green-500 mr-2' />
            Cable de Carga para Coche
          </li>
          <li className='flex items-center'>
            <FaCheck className='h-5 w-5 text-green-500 mr-2' />
            Cable de Carga AC
          </li>
          <li className='flex items-center'>
            <FaCheck className='h-5 w-5 text-green-500 mr-2' />
            Manual de Usuario
          </li>
        </ul>
        <div class='mt-8 text-center'>
          <a
            href='/docs/Oukitel_P2001E_Plus_manual_espanol.pdf'
            class='inline-block px-6 py-3 bg-white text-blue-600 font-bold rounded border-2 hover:bg-gray-300'
            download
          >
            Descargar Manual Español <FaFilePdf className='inline ml-2' />
          </a>
        </div>
      </div>
    </>
  );
}
