import { Helmet } from "react-helmet";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import Footer from "./Footer";

export default function Root() {
  return (
    <>
      <Helmet>
        <title>Golden Orinoco LLC</title>
        <meta
          name='description'
          content='Golden Orinoco: An Ecommerce Company Dedicated to Excellence in US Customer Service'
        />
      </Helmet>
      <Navbar />
      <Outlet />
      <Footer />
    </>
  );
}
