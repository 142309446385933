const MiniAbout = () => {
  return (
    <section
      className='pb-14 pt-20 sm:pb-20 sm:pt-32 lg:pb-32 dark:bg-black'
      aria-label='Razones para contratarnos'
    >
      <div className='mx-auto max-w-7xl px-4 sm:px-6 lg:px-8'>
        <div id='features' className='mx-auto max-w-2xl'>
          <h2>
            Multi-Platform E-commerce Company: Operating Multiple Online Brands
          </h2>
          <p className='mt-4'>
            Our company specializes in e-commerce retail, operating across
            multiple platforms including Amazon FBA, Etsy, and Shopify. We
            manage a diverse portfolio of e-commerce websites, each catering to
            specific niche markets. We're dedicated to providing high-quality
            products that enhance our customers' lives.
          </p>
          <p className='mt-4'>
            Across our various online stores, our product range encompasses
            several key categories: luxury bedding, sleep aids, wellness
            supplements, aromatherapy solutions, circadian rhythm optimization
            tools, and comfort accessories. These items are sourced from
            reputable manufacturers and undergo stringent quality control to
            ensure customer satisfaction.
          </p>
          <p className='mt-4'>
            Our selection features premium products chosen for their quality and
            effectiveness in enhancing our customers' lives. These items are
            sourced from reputable manufacturers and undergo stringent quality
            control to ensure customer satisfaction.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MiniAbout;
