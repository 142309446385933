import React from "react";

export default function TermsConditions() {
  return (
    <body class='bg-gray-100 text-gray-800 font-sans'>
      <div class='max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md'>
        <h1 class='text-2xl font-bold mb-4'>
          Términos y Condiciones de Venta de la Estación de Potencia a Baterías
        </h1>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>1. Introducción</h2>
          <p>
            Estos Términos y Condiciones regulan la compra y uso de la estación
            de potencia a baterías ("el Producto") adquirida a través de nuestro
            sitio web. Al realizar una compra, el cliente acepta estar sujeto a
            estos Términos y Condiciones.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            2. Información del Producto
          </h2>
          <p>
            El Producto es una estación de potencia a baterías diseñada para
            proporcionar energía en diversas situaciones. Las características y
            funcionalidades del Producto están detalladas en la descripción
            proporcionada en nuestro sitio web.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>3. Precio y Pago</h2>
          <ul class='list-disc list-inside'>
            <li>
              Los precios indicados en el sitio web son finales e incluyen los
              impuestos aplicables, salvo que se indique lo contrario.
            </li>
            <li>
              El pago se realiza a través de la pasarela de pago Stripe,
              asegurando un proceso de transacción seguro y cifrado.
            </li>
            <li>
              El cliente debe proporcionar información precisa y actualizada
              para completar la transacción. Nos reservamos el derecho de
              cancelar pedidos que no cumplan con estas condiciones.
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>4. Política de Envío</h2>
          <ul class='list-disc list-inside'>
            <li>
              Realizamos envíos a las direcciones proporcionadas al momento de
              la compra. Asegúrese de ingresar una dirección correcta.
            </li>
            <li>
              Los tiempos de entrega pueden variar según la ubicación del
              cliente. Proporcionaremos un número de seguimiento una vez que el
              Producto sea despachado.
            </li>
            <li>
              No nos hacemos responsables por retrasos causados por
              circunstancias fuera de nuestro control, como desastres naturales
              o problemas logísticos.
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>5. Garantía del Producto</h2>
          <ul class='list-disc list-inside'>
            <li>
              El Producto incluye una garantía limitada de tres (3) años a
              partir de la fecha de compra.
            </li>
            <li>
              La garantía cubre defectos de fabricación y materiales bajo
              condiciones normales de uso.
            </li>
            <li>
              La garantía no cubre daños causados por:
              <ul class='list-disc list-inside ml-6'>
                <li>Uso incorrecto o negligencia.</li>
                <li>Modificaciones no autorizadas.</li>
                <li>Daños accidentales o causados por desastres naturales.</li>
              </ul>
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            6. Devoluciones y Reembolsos
          </h2>
          <ul class='list-disc list-inside'>
            <li>
              Los clientes pueden solicitar una devolución dentro de los 30 días
              posteriores a la recepción del Producto, siempre que este se
              encuentre en su estado original y con el embalaje intacto.
            </li>
            <li>
              Los gastos de envío de devolución serán responsabilidad del
              cliente, salvo que el Producto presente defectos cubiertos por la
              garantía.
            </li>
            <li>
              Los reembolsos se emitirán a través del mismo método de pago
              utilizado durante la compra, una vez que el Producto sea
              inspeccionado y aprobado.
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>7. Uso del Producto</h2>
          <p>
            El cliente es responsable de utilizar el Producto de acuerdo con las
            instrucciones proporcionadas en el manual de usuario. No nos hacemos
            responsables de daños personales o materiales resultantes del uso
            indebido del Producto.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            8. Limitación de Responsabilidad
          </h2>
          <ul class='list-disc list-inside'>
            <li>
              En la medida permitida por la ley, nuestra responsabilidad se
              limita al precio pagado por el Producto.
            </li>
            <li>
              No seremos responsables por daños indirectos, incidentales o
              consecuentes derivados del uso del Producto.
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>9. Propiedad Intelectual</h2>
          <p>
            Todo el contenido del sitio web, incluidas las descripciones del
            Producto, es propiedad intelectual protegida. Su uso no autorizado
            está estrictamente prohibido.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            10. Modificaciones de los Términos y Condiciones
          </h2>
          <p>
            Nos reservamos el derecho de modificar estos Términos y Condiciones
            en cualquier momento. Las modificaciones serán efectivas al
            publicarse en nuestro sitio web.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            11. Ley Aplicable y Resolución de Disputas
          </h2>
          <ul class='list-disc list-inside'>
            <li>
              Estos Términos y Condiciones se rigen por las leyes del país de
              operación de la tienda.
            </li>
            <li>
              Cualquier disputa será resuelta mediante negociación amistosa o,
              si no es posible, mediante los tribunales competentes.
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>12. Contacto</h2>
          <p>
            Para cualquier consulta, puede comunicarse con nuestro servicio al
            cliente a través del correo electrónico info@goldenorinoco.com.
          </p>
        </section>

        <p class='text-center text-gray-600 text-sm'>
          Al realizar una compra, usted reconoce haber leído, entendido y
          aceptado estos Términos y Condiciones.
        </p>
      </div>
    </body>
  );
}
