import React, { useState } from "react";
import getStripe from "../../lib/getStripe";
import products from "../../data/products";
import CheckIcon from "../Icons/CheckIcon";
import ProcessingIcon from "../Icons/ProcessingIcon";
import { LANDING_PAGE_URL } from "../../constants/routes";

async function handleCheckout(priceId, setProcessing) {
  setProcessing(true);
  const stripe = await getStripe();
  const orderId = `order_${Date.now()}`; // Generate a unique order ID
  const { error } = await stripe.redirectToCheckout({
    lineItems: [
      {
        price: priceId,
        quantity: 1,
      },
    ],
    mode: "payment",
    successUrl: `${LANDING_PAGE_URL}/success?order_id=${orderId}`,
    cancelUrl: `${LANDING_PAGE_URL}/#comprar`,
    shippingAddressCollection: {
      allowedCountries: ["ES"], // Specify the allowed countries for shipping
    },
    clientReferenceId: orderId, // Use the client reference ID to set the order ID
  });
  if (error) {
    console.warn(error.message);
    setProcessing(false);
  }
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Buy() {
  const [processing, setProcessing] = useState(false);

  return (
    <>
      <div className='mx-auto mt-16 grid max-w-lg grid-cols-1 items-center gap-y-6 sm:mt-20 sm:gap-y-0 lg:max-w-4xl lg:grid-cols-2'>
        {products.map((product, index) => (
          <div
            key={product.id}
            className={classNames(
              product.featured
                ? "relative bg-gray-900 shadow-2xl"
                : "bg-white/60 sm:mx-8 lg:mx-0",
              product.featured
                ? ""
                : index === 0
                ? "rounded-t-3xl sm:rounded-b-none lg:rounded-tr-none lg:rounded-bl-3xl"
                : "sm:rounded-t-none lg:rounded-tr-3xl lg:rounded-bl-none",
              "rounded-3xl p-8 ring-1 ring-gray-900/10 sm:p-10"
            )}
          >
            <h3
              id={product.id}
              className={classNames(
                product.featured ? "text-indigo-400" : "text-indigo-600",
                "text-base/7 font-semibold"
              )}
            >
              {product.name}
            </h3>
            <img
              src={product.image}
              alt={product.name}
              className='mt-6 w-full rounded-lg'
            />
            <p className='mt-4 flex items-baseline gap-x-2'>
              <span
                className={classNames(
                  product.featured ? "text-white" : "text-gray-900",
                  "text-5xl font-semibold tracking-tight"
                )}
              >
                {product.price}
              </span>
              <span
                className={classNames(
                  product.featured ? "text-white" : "text-gray-900",
                  "text-xs font-semibold tracking-tight"
                )}
              >
                (incluye IVA)
              </span>
            </p>
            <p
              className={classNames(
                product.featured ? "text-gray-300" : "text-gray-600",
                "mt-6 text-base/7"
              )}
            >
              {product.description}
            </p>
            <ul
              className={classNames(
                product.featured ? "text-gray-300" : "text-gray-600",
                "mt-8 space-y-3 text-sm/6 sm:mt-10"
              )}
            >
              {product.features.map((feature) => (
                <li key={feature} className='flex gap-x-3'>
                  <CheckIcon
                    aria-hidden='true'
                    className={classNames(
                      product.featured ? "text-indigo-400" : "text-indigo-600",
                      "h-6 w-5 flex-none"
                    )}
                  />
                  {feature}
                </li>
              ))}
            </ul>
            <button
              aria-describedby={product.id}
              onClick={() => handleCheckout(product.priceId, setProcessing)}
              className={classNames(
                product.featured
                  ? "bg-indigo-500 text-white shadow-xs hover:bg-indigo-400 focus-visible:outline-indigo-500"
                  : "text-indigo-600 ring-1 ring-indigo-200 ring-inset hover:ring-indigo-300 focus-visible:outline-indigo-600",
                "mt-8 block rounded-md px-3.5 py-2.5 text-center text-sm font-semibold focus-visible:outline-2 focus-visible:outline-offset-2 sm:mt-10 w-full",
                processing && "opacity-50 cursor-not-allowed"
              )}
              disabled={processing}
            >
              {processing ? (
                <div className='flex items-center justify-center'>
                  <ProcessingIcon className='h-5 w-5 mr-2' />
                  Procesando...
                </div>
              ) : (
                "Comprar"
              )}
            </button>
          </div>
        ))}
      </div>
    </>
  );
}
