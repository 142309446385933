const products = [
  {
    id: "P2001E_Plus",
    name: "Estación Portátil P2001E Plus",
    description:
      "Energía portátil poderosa y confiable, diseñada con baterías de litio LiFePO4 de alta calidad para garantizar durabilidad, seguridad y rendimiento en cualquier situación.",
    price: "899 €",
    features: [
      "Capacidad batería: 2048Wh 51.2V",
      "Tipo Batería: LiFePO4 (3.500 ciclos)",
      "Tiempo carga AC: 1.5 hrs (1800W, 200V-240V)",
      "Tiempo carga Solar: 4 hrs (500W Max) 12V-50V, 10A",
      "Salida AC: 2400W onda senoidal pura (Pico 4800W)",
      "UPS: 1400W < 10ms",
      "Salida DC: 12V/10A, 24V/5A, 12V/10A, 12V/10A",
      "Temperatura de carga: 0 ~ 40°C",
      "Temperatura de descarga: -10°C ~ 40°C",
      "Dimesiones: 399 x 286 x 320 mm",
      "Peso: alrededor de 21,5 kg",
      "Garantía: 3 años",
    ],
    featured: false,
    priceId: process.env.REACT_APP_PUBLIC_STRIPE_PRICE_ID_1,
    image: "/images/P2001E/P2001E_Plus_2_3-4_small.webp",
  },
  {
    id: "P2001E_Plus_mas_panel",
    name: "Estación Portátil P2001E Plus + Panel Solar 200W PV200",
    description:
      "¡Oferta Limitada! Lleva la estación portátil P2001E Plus junto con el panel solar PV200 con un descuento exclusivo. Con 200W de potencia, nuestro panel solar es la solución ideal para cargar tu generador en cualquier lugar, ofreciéndote energía limpia y accesible siempre que la necesites.",
    features: [
      "Nuestra P2001E Plus",
      "Panel Solar PV200",
      "Células de silicón monocristalino de alta eficiencia",
      "Máxima potencia (PPM): 200W ± 5%",
      "Voltaje circuito abierto (Voc): 24.7V ± 5%",
      "Corriente circuito corto (Isc): 11.2A ± 5%",
      "Voltaje máximo potencia (Vmp): 21V ± 5%",
      "Corriente máxima potencia (Imp): 200W ± 5%",
      "Dimensiones (Guardado): 627.5 x 544 x 55 mm",
      "Dimensiones (Desplegado): 2385 x 544 x 21 mm",
      "A prueba de agua y polvo (IP68)",
      "Peso: 21,5 kg",
      "Peso panel solar: 8 kg",
      "Garantía: 3 años",
    ],
    price: "1.199 €",
    featured: true,
    priceId: process.env.REACT_APP_PUBLIC_STRIPE_PRICE_ID_2,
    image: "/images/P2001E/P2001E_Plus_PV200_small.webp",
  },
];

export default products;
