import React from "react";

export default function SectionBig({ section }) {
  return (
    <>
      <div
        className='relative bg-cover bg-center bg-no-repeat h-auto pt-6 sm:pt-24 pb-[56.25%] sm:rounded-lg mb-12 max-w-3xl mx-auto'
        style={{
          backgroundImage: `url(${section.image})`,
        }}
      >
        <div className='max-w-7xl px-4 sm:px-6 lg:px-8'>
          <h3 className='text-3xl font-bold text-black'>{section.title}</h3>
          <p className='mt-1 text-black text-sm pb-24'>{section.description}</p>
        </div>
      </div>
      {/* <hr className='my-8 border-t-2 border-gray-300' /> */}
    </>
  );
}
