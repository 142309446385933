const faqs = [
  {
    question:
      "¿Cómo puedo saber si mi aparato puede funcionar bien con el P2001E Plus?",
    answer:
      "El P2001E Plus es un generador portátil con una potencia nominal de 2400 W, lo que significa que puede alimentar dispositivos cuya potencia combinada no supere este límite. Para comprobar si tu aparato es compatible, revisa su potencia nominal en su etiqueta o manual. Si la suma de la potencia de tus dispositivos conectados está dentro del rango, el P2001E Plus será capaz de alimentarlos sin problemas. Ideal para electrodomésticos, herramientas y equipos electrónicos.",
  },
  {
    question: "¿P2001E Plus tiene función UPS?",
    answer:
      "Sí, el P2001E Plus cuenta con una función de alimentación ininterrumpida (UPS) que garantiza un tiempo de conmutación inferior a 10 milisegundos. Esto protege tus dispositivos electrónicos sensibles ante cortes de energía.",
  },
  {
    question: "¿Cómo podemos saber el tiempo restante usando?",
    answer:
      "El P2001E Plus está equipado con una pantalla que muestra información clave, incluyendo el tiempo estimado de uso restante basado en la carga actual. Simplemente enciende el dispositivo y verifica esta información en la pantalla.",
  },
  {
    question:
      "¿Se puede cargar el P2001E Plus con paneles solares y un tomacorriente de pared simultáneamente?",
    answer:
      "Sí, el P2001E Plus admite la carga simultánea utilizando paneles solares y un tomacorriente de pared. Esto permite reducir el tiempo de carga a solo 50 minutos, ideal para situaciones en las que necesitas recargar rápidamente.",
  },
  {
    question: "¿P2001E Plus admite el control de aplicaciones?",
    answer:
      "Sí, puedes controlar el P2001E Plus de forma remota a través de Bluetooth o WiFi. Solo necesitas conectar tu dispositivo móvil utilizando la aplicación dedicada para acceder a información en tiempo real y ajustes.",
  },
  {
    question: "¿Por qué hay un sonido al usarlo?",
    answer:
      "El sonido que escuchas al usar el P2001E Plus proviene de su ventilador de refrigeración o del relé interno. Estos componentes aseguran que el generador funcione de manera eficiente y segura, especialmente durante cargas altas.",
  },
  {
    question:
      "¿Puedo utilizar paneles solares de terceros y cuáles son los requisitos previos?",
    answer:
      "Sí, puedes utilizar paneles solares de terceros para cargar el P2001E Plus, siempre que cumplan con los siguientes requisitos:\n1. Conectores MC4 compatibles.\n2. Voltaje de circuito abierto (Voc) en el rango de 12 a 60 V.\n3. Corriente máxima de 15 A.\nAsegúrate de verificar las especificaciones de los paneles antes de conectarlos.",
  },
  {
    question: "¿Cómo debemos limpiar el producto?",
    answer:
      "Para limpiar el P2001E Plus, utiliza un paño o pañuelo seco, suave y limpio. Esto ayudará a mantener el dispositivo en buen estado. Evita el uso de productos químicos o agua para evitar daños.",
  },
  {
    question: "¿Cómo almacenar?",
    answer:
      "Apaga el P2001E Plus antes de almacenarlo y colócalo en un lugar seco, alejado del agua y la humedad. Recomendamos utilizar el producto cada tres meses para mantenerlo en óptimas condiciones. Antes de almacenarlo por largos períodos, agota la energía restante y recárgalo hasta un porcentaje deseado, como el 50 %).",
  },
  {
    question:
      "¿Cuál es el rango de temperatura de funcionamiento del P2001E Plus?",
    answer:
      "El P2001E Plus está diseñado para operar de manera segura en un rango de temperatura de 0 °C a 40 °C. Es importante usar el generador dentro de este rango para garantizar su rendimiento y evitar daños. Si planeas usarlo en condiciones extremas, considera protegerlo del frío o el calor excesivo para mantener su funcionamiento óptimo.",
  },
  {
    question: "¿El P2001E Plus es resistente a salpicaduras?",
    answer:
      "Sí, el P2001E Plus es resistente a salpicaduras (splash proof), lo que significa que puede soportar pequeñas cantidades de agua, como salpicaduras accidentales. Sin embargo, no es completamente impermeable, por lo que debe evitarse exponerlo directamente a la lluvia o sumergirlo en agua para garantizar su durabilidad y correcto funcionamiento.",
  },
];

export default faqs;
