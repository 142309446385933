import { useState, useEffect } from "react";
import { getCookieConsentValue } from "react-cookie-consent";
import GoogleAnalytics from "./lib/GoogleAnalytics";
import FacebookPixel from "./lib/FacebookPixel";
import { Toaster } from "react-hot-toast";

import { Cookies } from "react-cookie-consent";
import CookieConsentBanner from "./components/CookieConsentBanner";

import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom";

import Root from "./components/Root";
import LandingRoot from "./components/Landing/LandingRoot";

import PageNotFound from "./pages/PageNotFound";
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import ContactUs from "./pages/ContactUs";
import Landing from "./pages/Landing";
import Success from "./pages/Success";
import Cancel from "./pages/Cancel";
import TermsConditions from "./pages/TermsConditions";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import PoliticaPrivacidad from "./pages/PoliticaPrivacidad";
import Warranty from "./components/Landing/Warranty";

const trackingId = process.env.REACT_APP_GA_TRACKING_ID;
const pixelId = process.env.REACT_APP_FACEBOOK_PIXEL_ID;

const appRouter = createBrowserRouter(
  createRoutesFromElements(
    <>
      {/* Main site */}
      <Route path='/' element={<Root />} errorElement={<PageNotFound />}>
        <Route index element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/contact-us' element={<ContactUs />} />
        <Route path='/privacy-policy' element={<PrivacyPolicy />} />
        <Route path='*' element={<PageNotFound />} />
      </Route>
      {/* estacion-energia-portatil-oukitel-p2001-plus */}
      <Route
        path='/landing/estacion-energia-portatil-oukitel-p2001-plus'
        element={<LandingRoot />}
      >
        <Route index element={<Landing />} />
        <Route path='success' element={<Success />} />
        <Route path='cancel' element={<Cancel />} />
        <Route path='terms-conditions' element={<TermsConditions />} />
        <Route path='politica-privacidad' element={<PoliticaPrivacidad />} />
        <Route path='warranty' element={<Warranty />} />
        {/* Add more routes inside LandingRoot as needed */}
      </Route>
    </>
  )
);

function App() {
  const [userConsent, setUserConsent] = useState(getCookieConsentValue());

  useEffect(() => {
    // Sync state with cookie in case it changes from elsewhere
    const consent = Cookies.get("userConsent");
    if (consent !== userConsent) {
      setUserConsent(consent);
    }
  }, [userConsent]);

  return (
    <>
      {/* <GoogleAnalytics trackingId={trackingId} />
      <FacebookPixel pixelId={pixelId} /> */}
      <CookieConsentBanner setUserConsent={setUserConsent} />
      {/* Google Analytics y Facebook Pixel with Consent */}
      {userConsent === "true" && (
        <>
          <GoogleAnalytics trackingId={trackingId} />
          <FacebookPixel pixelId={pixelId} />
        </>
      )}
      <Toaster position='top-right' />
      <RouterProvider router={appRouter} />
    </>
  );
}

export default App;
