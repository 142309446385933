import React, { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "react-hot-toast";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { SiMinutemailer } from "react-icons/si";

const ContactForm = () => {
  const [token, setToken] = useState();
  const [refreshRecaptcha, setRefreshRecaptcha] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    reset, // Add reset function
  } = useForm();

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    setRefreshRecaptcha((r) => !r);

    const { name, email, message } = formData;

    try {
      const res = await fetch(`/.netlify/functions/pmaEmail`, {
        method: "POST",
        body: JSON.stringify({
          from: "info@goldenorinoco.com",
          to: "ricardo@goldenorinoco.com",
          subject: `Contacto P2001E Plus de ${name}`,
          textBody: `Forma contacto P20001E Plus.\n Nombre: ${name}\n Correo: ${email}\n Mensaje: ${message}`,
          htmlBody: `<html><body style="font-family: 'Open Sans', 'Helvetica Neue', sans-serif; margin: 0 auto; padding: 20px;"><h1>Forma Contacto</h1><h2>${name}</h2><h3>${email}</h3><p>${message}</p></body></html>`,
          token, // Include the reCAPTCHA token
        }),
      });

      if (!res.ok) {
        toast.error(`Hubo un error: ${res.status}, ${res.statusText}`);
      } else {
        toast.success("Tu mensaje ha sido enviado con éxito");
        reset(); // Reset the form
        navigate(
          "/landing/estacion-energia-portatil-oukitel-p2001-plus#comprar"
        );
      }
    } catch (error) {
      console.log("There was an error: " + error.message);
      toast.error("There was an error: " + error.message);
    }
  };

  const onVerify = useCallback(
    (token) => {
      setToken(token);
    },
    [setToken]
  );

  return (
    <>
      <GoogleReCaptcha
        onVerify={onVerify}
        refreshReCaptcha={refreshRecaptcha}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='mb-4'>
          <label htmlFor='name'>Nombre</label>
          <div className='relative mt-2 rounded-md shadow-sm'>
            <input
              {...register("name", {
                required: true,
              })}
              type='text'
              id='name'
              name='name'
              value={formData.name}
              onChange={handleChange}
              className={errors.name ? "input-error" : "input-normal"}
            />
            <div className={errors.name ? "input-icon" : "hidden"}>
              <AiOutlineExclamationCircle
                className='h-5 w-5 text-red-500'
                aria-hidden='true'
              />
            </div>
          </div>
          {errors.name && (
            <span className='text-sm text-red-600'>Tu nombre es requerido</span>
          )}
        </div>
        <div className='mb-4'>
          <label htmlFor='email'>Correo Electrónico</label>
          <div className='relative mt-2 rounded-md shadow-sm'>
            <input
              {...register("email", {
                required: true,
              })}
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              className={errors.name ? "input-error" : "input-normal"}
            />
            <div className={errors.name ? "input-icon" : "hidden"}>
              <AiOutlineExclamationCircle
                className='h-5 w-5 text-red-500'
                aria-hidden='true'
              />
            </div>
          </div>
          {errors.email && (
            <span className='text-sm text-red-600'>
              Tu correo electrónico es requerido
            </span>
          )}
        </div>
        <div className='mb-4'>
          <label htmlFor='message'>Mensaje</label>
          <div className='relative mt-2 rounded-md'>
            <textarea
              {...register("message", {
                required: true,
              })}
              id='message'
              name='message'
              rows='4'
              value={formData.message}
              onChange={handleChange}
              className={errors.name ? "input-error" : "input-normal"}
            ></textarea>
            <div className={errors.name ? "input-icon" : "hidden"}>
              <AiOutlineExclamationCircle
                className='h-5 w-5 text-red-500'
                aria-hidden='true'
              />
            </div>
          </div>
          {errors.message && (
            <span className='text-sm text-red-600'>
              Un mensaje es requerido
            </span>
          )}
        </div>
        <button disabled={isSubmitting} type='submit' className='btn'>
          {isSubmitting && (
            <svg
              className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                strokeWidth='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          )}
          Enviar <SiMinutemailer className='ml-2' />
        </button>
      </form>
    </>
  );
};

export default ContactForm;
