import React from "react";

export default function SectionHero() {
  return (
    <div
      className='relative bg-cover bg-center bg-no-repeat h-screen pb-[56.25%]'
      style={{
        backgroundImage: "url('/images/P2001E/P2001E_camping.webp')",
      }}
    >
      <div className='py-12 sm:py-32 lg:pb-40'>
        <div className='mx-auto max-w-7xl px-6 lg:px-8'>
          <div className='mx-auto max-w-2xl text-center text-gray-100'>
            <h1 className='text-5xl font-semibold tracking-tight text-balance text-white sm:text-7xl drop-shadow-lg'>
              Energía donde la necesitas, cuando la necesitas
            </h1>
            <p className='mt-8 text-lg font-medium text-pretty text-gray-100 sm:text-xl/8'>
              Libera tu vida de interrupciones con la Estación de Energía
              Portátil Oukitel P2001E Plus. Silenciosa, ecológica y confiable:
              ideal para cortes de luz en casa, aventuras al aire libre y
              trabajos en cualquier lugar. Tú decides dónde usarla.
            </p>
            <div className='mt-10 flex flex-col sm:flex-row items-center justify-center gap-y-4 sm:gap-x-6'>
              <a
                id='buy-now-button'
                href='#comprar'
                className='rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full sm:w-auto'
              >
                Quiero mi Energía Portátil <span aria-hidden='true'>⚡️</span>
              </a>
              <a
                href='#caracteristicas'
                className='rounded-md bg-slate-200 px-3.5 py-2.5 text-sm font-semibold text-gray-600 shadow-xs hover:bg-slate-100 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-slate-600 w-full sm:w-auto'
              >
                ¿Cómo funciona? <span aria-hidden='true'>🤔</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
