import React from "react";

export default function Warranty() {
  return (
    <div class=' text-gray-800 font-sans leading-relaxed'>
      <div class='max-w-4xl mx-auto bg-white shadow-md rounded-md p-6 my-10'>
        <h1 class='text-2xl font-bold text-center mb-6'>
          Términos y Condiciones de la Garantía OUKITEL
        </h1>

        <section class='mb-6'>
          <p>
            Esta garantía sólo está disponible para los clientes que hayan
            comprado en los sitios web oficiales de OUKITEL, en las tiendas
            OUKITEL Amazon o en cualquier distribuidor autorizado de OUKITEL.
            Las partes que deseen participar en el Programa de Garantía deben
            cumplir con los procedimientos y requisitos establecidos en esta
            política.
          </p>
          <p class='mt-2'>
            Nuestro amable y atento equipo de asistencia estará encantado de
            ayudarle con sus consultas relacionadas con la garantía. No dude en
            ponerse en contacto con nosotros a través de{" "}
            <a href='mailto:support@oukitelpower.com' class='text-blue-500'>
              support@oukitelpower.com
            </a>{" "}
            para obtener más información.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-4'>Periodo de Garantía</h2>
          <table class='table-auto w-full border border-gray-300'>
            <thead>
              <tr class='bg-gray-200'>
                <th class='border px-4 py-2 text-left'>Producto Básico</th>
                <th class='border px-4 py-2 text-left'>Garantía</th>
                <th class='border px-4 py-2 text-left'>Garantía Extensión</th>
                <th class='border px-4 py-2 text-left'>Total Garantía</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class='border px-4 py-2'>
                  CN505/P2001/P1201/P5000/BP2000/B2000 Centrales eléctricas y
                  batería ampliable
                </td>
                <td class='border px-4 py-2'>1 año</td>
                <td class='border px-4 py-2'>2 años</td>
                <td class='border px-4 py-2'>3 años</td>
              </tr>
              <tr>
                <td class='border px-4 py-2'>Panel solar PV100/PV200/PV400</td>
                <td class='border px-4 py-2'>1 año</td>
                <td class='border px-4 py-2'>2 años</td>
                <td class='border px-4 py-2'>3 años</td>
              </tr>
            </tbody>
          </table>
          <p class='text-sm text-gray-600 mt-4'>
            La tabla anterior indica los periodos de garantía de los productos
            comprados a OUKITEL o a sus socios minoristas. Los periodos de
            garantía de los productos solicitados a través de las campañas de
            crowdfunding de OUKITEL (Kickstarter, Indiegogo, etc.) pueden
            diferir. Consulte la documentación del producto para obtener más
            información.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-4'>Exclusiones de la Garantía</h2>
          <p>
            En caso de daños relacionados con las causas enumeradas a
            continuación, no se reconocerá ni aceptará ninguna reclamación de
            garantía:
          </p>
          <ul class='list-disc pl-6 mt-2 space-y-1'>
            <li>No puede presentar prueba de compra.</li>
            <li>
              Daños causados por fuerza mayor (tormentas, incendios,
              inundaciones, etc.).
            </li>
            <li>Uso indebido, abuso, robo, o pérdida del producto.</li>
            <li>Intentos de reparación no autorizados.</li>
            <li>Productos adquiridos a distribuidores no autorizados.</li>
          </ul>
          <p class='mt-2'>
            Consulte el documento completo para obtener la lista detallada de
            exclusiones.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-4'>Cómo Reclamar la Garantía</h2>
          <ol class='list-decimal pl-6 space-y-2'>
            <li>
              Reclame su garantía en{" "}
              <a
                href='https://eu.oukitel.com/pages/claim-warranty'
                class='text-blue-500'
              >
                esta página
              </a>{" "}
              o escriba a{" "}
              <a href='mailto:support@oukitelpower.com' class='text-blue-500'>
                support@oukitelpower.com
              </a>
              .
            </li>
            <li>
              Prepare documentación o un vídeo con información como número de
              pedido, prueba de número de serie, y evidencia del defecto.
            </li>
            <li>
              Envíe los artículos al centro de servicio postventa de OUKITEL con
              el número RMA incluido en la etiqueta de envío.
            </li>
          </ol>
        </section>

        <footer class='text-sm text-center text-gray-500 mt-6'>
          &copy; 2025 OUKITEL. Todos los derechos reservados.
        </footer>
      </div>
    </div>
  );
}
