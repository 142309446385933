import { Link, useLocation } from "react-router-dom";

export default function Success() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const orderId = queryParams.get("order_id");

  const isValidOrderId = orderId && /^order_\d+$/.test(orderId);

  return (
    <div className='mx-auto mt-16 max-w-lg text-center'>
      <div className='bg-white/60 p-8 ring-1 ring-gray-900/10 rounded-3xl shadow-2xl'>
        {isValidOrderId ? (
          <>
            <h1 className='text-3xl font-semibold text-indigo-600'>
              ¡Gracias por tu compra!
            </h1>
            <p className='mt-4 text-lg text-gray-600'>
              Tu pedido ha sido procesado exitosamente. Te hemos enviado un
              correo electrónico con los detalles de tu compra.
            </p>
            <p className='mt-4 text-lg text-gray-600'>
              Número de pedido: <strong>{orderId}</strong>
            </p>
            <p className='mt-4 text-lg text-gray-600'>
              Si tienes alguna pregunta, no dudes en contactarnos.
            </p>
          </>
        ) : (
          <>
            <h1 className='text-3xl font-semibold text-red-600'>Error</h1>
            <p className='mt-4 text-lg text-gray-600'>
              No se ha encontrado un número de pedido válido. Por favor,
              asegúrate de haber completado el proceso de compra correctamente.
            </p>
          </>
        )}
        <Link
          to='/landing/estacion-energia-portatil-oukitel-p2001-plus'
          className='mt-8 block rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-xs hover:bg-indigo-500 focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
        >
          Volver al inicio
        </Link>
      </div>
    </div>
  );
}
