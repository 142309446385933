import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";
import { HiMiniPlusSmall, HiMiniMinusSmall } from "react-icons/hi2";
import faqs from "../../data/faqs";

export default function Example() {
  return (
    <div className='bg-gray-900 mx-auto max-w-3xl rounded-lg'>
      <div className='mx-auto max-w-7xl px-6 py-6 sm:py-12 lg:px-8 lg:py-24'>
        <div className='mx-auto max-w-4xl divide-y divide-white/10'>
          <h2 className='text-4xl font-semibold tracking-tight text-indigo-600 sm:text-5xl'>
            Preguntas Frecuentes
          </h2>
          <dl className='mt-10 space-y-6 divide-y divide-white/10'>
            {faqs.map((faq) => (
              <Disclosure key={faq.question} as='div' className='pt-6'>
                <dt>
                  <DisclosureButton className='group flex w-full items-start justify-between text-left text-white'>
                    <span className='text-base/7 font-semibold'>
                      {faq.question}
                    </span>
                    <span className='ml-6 flex h-7 items-center'>
                      <HiMiniPlusSmall
                        aria-hidden='true'
                        className='size-6 group-data-[open]:hidden'
                      />
                      <HiMiniMinusSmall
                        aria-hidden='true'
                        className='size-6 group-[&:not([data-open])]:hidden'
                      />
                    </span>
                  </DisclosureButton>
                </dt>
                <DisclosurePanel
                  transition
                  as='dd'
                  className='mt-2 pr-12 origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0'
                >
                  <p className='text-base/7 text-gray-300'>{faq.answer}</p>
                </DisclosurePanel>
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
}
