import React from "react";

export default function PoliticaPrivacidad() {
  return (
    <body class='bg-gray-100 text-gray-800 font-sans'>
      <div class='max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md'>
        <h1 class='text-2xl font-bold mb-4'>Política de Privacidad</h1>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>1. Introducción</h2>
          <p>
            Esta Política de Privacidad describe cómo recopilamos, usamos y
            protegemos su información personal cuando utiliza nuestro sitio web
            y nuestros servicios. Esto incluye el uso de herramientas como
            Google Analytics y Facebook Pixel, así como el procesamiento de
            pagos a través de Stripe. Al acceder a nuestro sitio web, usted
            acepta las prácticas descritas en esta política.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>2. Información Recopilada</h2>
          <p>Podemos recopilar la siguiente información:</p>
          <ul class='list-disc list-inside'>
            <li>Nombre y apellidos.</li>
            <li>Correo electrónico.</li>
            <li>Dirección de envío y facturación.</li>
            <li>Datos de pago procesados de manera segura por Stripe.</li>
            <li>
              Información sobre su actividad en nuestro sitio web recopilada
              mediante Google Analytics y Facebook Pixel, incluyendo:
              <ul class='list-disc ml-6'>
                <li>Páginas visitadas.</li>
                <li>Tiempo de permanencia en el sitio.</li>
                <li>Interacciones con nuestro contenido.</li>
                <li>Dirección IP y datos del navegador.</li>
              </ul>
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>3. Uso de la Información</h2>
          <p>
            La información recopilada se utiliza para los siguientes propósitos:
          </p>
          <ul class='list-disc list-inside'>
            <li>Procesar y completar su compra.</li>
            <li>
              Enviar confirmaciones y actualizaciones relacionadas con su
              pedido.
            </li>
            <li>Responder a consultas y brindar soporte al cliente.</li>
            <li>
              Analizar el comportamiento de los usuarios en nuestro sitio web
              para mejorar la experiencia de navegación y optimizar nuestras
              estrategias de marketing.
            </li>
          </ul>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>4. Uso de Stripe</h2>
          <p>
            Utilizamos Stripe como proveedor de servicios de pago. Al realizar
            una compra, su información de pago es procesada directamente por
            Stripe y no se almacena en nuestros servidores. Para más información
            sobre cómo Stripe maneja su información, consulte su{" "}
            <a
              href='https://stripe.com/privacy'
              class='text-blue-500 underline'
            >
              Política de Privacidad
            </a>
            .
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            5. Uso de Google Analytics y Facebook Pixel
          </h2>
          <p>
            <strong>Google Analytics</strong>: Utilizamos esta herramienta para
            recopilar datos sobre el uso de nuestro sitio web, como las páginas
            visitadas y el tiempo de permanencia. Esto nos permite entender
            mejor el comportamiento de los usuarios y mejorar nuestro sitio web.
          </p>
          <p>
            <strong>Facebook Pixel</strong>: Esta herramienta nos permite
            rastrear interacciones en el sitio web para ofrecer anuncios
            personalizados y medir la efectividad de nuestras campañas
            publicitarias en Facebook.
          </p>
          <p>
            Ambas herramientas recopilan información de forma anónima y no
            permiten identificar directamente a los usuarios. Puede optar por no
            participar en la recopilación de datos mediante las configuraciones
            de su navegador o siguiendo las instrucciones en las páginas de
            configuración de Google y Facebook.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>6. Protección de Datos</h2>
          <p>
            Mantenemos medidas de seguridad razonables para proteger su
            información personal contra accesos no autorizados, pérdidas o
            alteraciones. No compartimos su información personal con terceros,
            excepto cuando sea necesario para procesar su pedido, optimizar
            nuestra plataforma o cumplir con la ley.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>
            7. Cookies y Tecnologías Relacionadas
          </h2>
          <p>
            Nuestro sitio web utiliza cookies y tecnologías similares para
            mejorar la experiencia del usuario, recopilar estadísticas y mostrar
            anuncios personalizados. Puede administrar sus preferencias de
            cookies a través de la configuración de su navegador.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>8. Derechos del Usuario</h2>
          <p>
            Usted tiene derecho a acceder, actualizar o eliminar su información
            personal en cualquier momento. También puede optar por no participar
            en el seguimiento de datos analíticos o publicitarios. Para ejercer
            estos derechos, por favor contacte a nuestro servicio al cliente a
            través del correo electrónico proporcionado en nuestro sitio web.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>9. Cambios a esta Política</h2>
          <p>
            Nos reservamos el derecho de modificar esta Política de Privacidad
            en cualquier momento. Las modificaciones serán publicadas en esta
            página con una fecha de actualización. Recomendamos revisar
            periódicamente esta página para mantenerse informado.
          </p>
        </section>

        <section class='mb-6'>
          <h2 class='text-xl font-semibold mb-2'>10. Contacto</h2>
          <p>
            Si tiene preguntas o inquietudes sobre esta Política de Privacidad,
            por favor contáctenos a través del correo electrónico proporcionado
            en nuestro sitio web.
          </p>
        </section>

        <p class='text-center text-gray-600 text-sm'>
          Al utilizar este sitio web, usted acepta los términos establecidos en
          esta Política de Privacidad.
        </p>
      </div>
    </body>
  );
}
