import React from "react";

export default function SectionPosibilities() {
  return (
    <div class='max-w-3xl mx-auto p-6'>
      <h1 class='text-3xl font-bold text-blue-600 mb-4'>
        Una estación portátil, infinitas posibilidades
      </h1>

      <section class='mb-8'>
        <p class='text-lg leading-relaxed'>
          El <strong class='font-semibold'>P2001E Plus</strong> es como ese
          amigo confiable que nunca te deja tirado: silencioso, eficiente y
          siempre listo para ayudarte. Desde mantener tus bebidas frías durante
          una escapada al campo hasta proteger tus dispositivos en un apagón,
          este generador está diseñado para adaptarse a tu vida. Aquí tienes
          algunos ejemplos de todo lo que puede hacer:
        </p>
      </section>

      <section class='space-y-6'>
        <div>
          <h2 class='text-xl font-bold text-blue-500 mb-2'>
            1. En casa durante cortes de luz:
          </h2>
          <ul class='list-disc list-inside space-y-2'>
            <li>
              Mantén tu nevera estándar encendida hasta{" "}
              <span class='font-medium'>20 horas</span> para proteger alimentos
              y medicinas esenciales.
            </li>
            <li>
              Conecta tu router Wi-Fi para seguir trabajando o mantener a tu
              familia conectada hasta <span class='font-medium'>100 horas</span>
              . ¡Que nadie entre en pánico al perder Wi-Fi!
            </li>
            <li>
              Alimenta luces LED y un televisor para que tu hogar siga siendo
              cómodo y funcional.
            </li>
          </ul>
        </div>

        <div>
          <h2 class='text-xl font-bold text-blue-500 mb-2'>
            2. Para tus aventuras al aire libre:
          </h2>
          <ul class='list-disc list-inside space-y-2'>
            <li>
              Cocina tus comidas favoritas en una vitrocerámica portátil durante{" "}
              <span class='font-medium'>2.5 horas</span>.
            </li>
            <li>
              Mantén una nevera pequeña funcionando hasta{" "}
              <span class='font-medium'>30 horas</span> para bebidas frescas y
              alimentos.
            </li>
            <li>
              Carga tu dron, cámara y smartphone para capturar momentos
              inolvidables sin preocuparte por la batería.
            </li>
          </ul>
        </div>

        <div>
          <h2 class='text-xl font-bold text-blue-500 mb-2'>
            3. En furgonetas camperizadas:
          </h2>
          <ul class='list-disc list-inside space-y-2'>
            <li>
              Alimenta luces LED, una nevera portátil y una batería externa
              grande, todo al mismo tiempo.
            </li>
            <li>
              Conecta una estufa portátil para mantener el confort durante
              noches frías.
            </li>
            <li>
              Mantén tu ordenador funcionando para trabajar mientras viajas (o
              ver Netflix en la cama).
            </li>
          </ul>
        </div>

        <div>
          <h2 class='text-xl font-bold text-blue-500 mb-2'>
            4. Para fotógrafos y creadores:
          </h2>
          <ul class='list-disc list-inside space-y-2'>
            <li>
              Recarga tu laptop mientras editas en tiempo real, manteniendo tus
              cámaras DSLR y flashes siempre listos.
            </li>
            <li>
              Alimenta un proyector portátil para mostrar tu trabajo al
              instante.
            </li>
            <li>
              Carga varias baterías recargables para un día completo de
              producción.
            </li>
          </ul>
        </div>

        <div>
          <h2 class='text-xl font-bold text-blue-500 mb-2'>
            5. En mercados o eventos:
          </h2>
          <ul class='list-disc list-inside space-y-2'>
            <li>
              Conecta un terminal de pago, luces decorativas y un altavoz
              portátil para destacar tu puesto.
            </li>
            <li>
              Alimenta un pequeño ventilador o calefactor según las condiciones
              climáticas.
            </li>
            <li>
              Usa una cafetera eléctrica para ofrecer una experiencia
              profesional a tus clientes.
            </li>
            <li>Conecta guirnaldas para la terraza del bar.</li>
          </ul>
        </div>
      </section>
      <section class='mt-8'>
        <p class='text-xl font-semibold text-blue-600'>
          Con el <span class='font-bold'>P2001E Plus</span>, tienes la
          tranquilidad de saber que siempre contarás con energía confiable y
          portátil, sin importar la situación.
        </p>
      </section>
    </div>
  );
}
