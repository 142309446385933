import React from "react";

export default function ProcessingIcon({ className }) {
  return (
    <svg
      className={`animate-spin ${className}`}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 24 24'
      fill='none'
      stroke='currentColor'
      strokeWidth='2'
      strokeLinecap='round'
      strokeLinejoin='round'
    >
      <circle cx='12' cy='12' r='10' strokeOpacity='0.25' />
      <path d='M4 12a8 8 0 018-8' strokeOpacity='0.75' />
    </svg>
  );
}
